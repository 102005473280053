/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable dot-notation */
import { Box } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import Tooltip from './Tooltip'
import '../station.scss'
import {
  color1a, color2a, color3a, color4a, color5a, color6a, color7a, color8a, color9a, color10a,
  color1b, color2b, color3b, color4b, color5b, color6b, color7b, color8b, color9b, color10b,
} from '../colorSchemes'

type Props = {
  aggregationCategory: string;
}

const getLegend = aggregationCategory => {
  if (aggregationCategory === 'Nombre total de circulations') {
    return aggregationCategory
  }
  return `${aggregationCategory} (en minutes)`
}

export default function BarChart({ aggregationCategory }: Props): ReactElement {
  const {
    trackUsage, fromGroix, filters, circulationType,
  } = useSelector((state: RootState) => state.metrics)
  const { monochrome } = useSelector((state: RootState) => state.stations)
  const monoColors = !fromGroix ? [
    color1a, color2a, color3a, color4a, color5a, color6a, color7a, color8a, color9a, color10a,
  ] : [color6a]
  const multiColors = !fromGroix ? [
    color1b, color5b, color8b, color2b, color9b, color6b, color7b, color3b, color10b, color4b,
  ] : [color6b]

  function toHoursAndMinutes(totalMinutes) {
    // Calculate the number of days
    const days = Math.floor(totalMinutes / 1440)

    // Calculate the number of hours
    const hours = Math.floor((totalMinutes % 1440) / 60)

    // Calculate the number of minutes
    const remainingMinutes = totalMinutes % 60

    return `${days > 0 ? days : ''}${days > 0 ? 'j' : ''} ${hours > 0 ? hours : ''}${hours > 0 ? 'h' : ''} ${remainingMinutes}m`
  }

  const BarTotalsLayer = props => {
    const labelOffset = 10
    const labelFontSize = 12
    if (props?.bars?.length === 0) return null
    const totals = {}
    const bandwidth = props.bars[0].width
    props.bars.forEach(bar => {
      const { indexValue } = bar.data
      if (!(indexValue in totals)) {
        totals[indexValue] = 0
      }
      if (!bar.data.hidden) {
        totals[indexValue] += bar.data.value
      }
    })
    const labels = Object.keys(totals).map(indexValue => {
      const x = props.xScale(indexValue) + bandwidth / 2
      const y = props.yScale(totals[indexValue]) - labelOffset
      return (
        <text
          key={`total.${indexValue}`}
          x={x}
          y={y}
          textAnchor="middle"
          fontWeight="bold"
          fontSize={labelFontSize}
        >
          {aggregationCategory !== 'Nombre total de circulations' ? toHoursAndMinutes(totals[indexValue]) : totals[indexValue]}
        </text>
      )
    })
    return <>{labels}</>
  }
  return (
    <Box sx={{ height: '55vh' }}>
      <ResponsiveBar
        data={trackUsage}
        keys={filters && filters.circulation_type.length !== 0 ? filters.circulation_type : circulationType}
        indexBy="x"
        margin={{
          top: 100, right: 20, bottom: 60, left: 60,
        }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={monochrome ? monoColors : multiColors}
        theme={{
          grid: {
            line: {
              strokeWidth: 1,
              stroke: '#EDEDED',
              opacity: 0.5,
            },
          },
          legends: {
            text: {
              fontSize: 14,
            },
          },
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Voies occupées',
          legendPosition: 'middle',
          legendOffset: 50,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: getLegend(aggregationCategory),
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        isInteractive
        tooltip={({ id, value }) => (
          <Tooltip label={id} value={value} aggregationCategory={aggregationCategory} />
        )}
        enableLabel={false}
        legends={fromGroix ? [] : [
          {
            dataFrom: 'keys',
            anchor: 'top',
            direction: 'row',
            translateY: -100,
            itemsSpacing: 20,
            itemWidth: 120,
            itemHeight: 60,
            symbolSize: 12,
            symbolShape: ({ fill }) => <rect x={-50} y={25} width="60px" height="10px" rx="5" fill={fill} />,
          },
        ]}
        onMouseEnter={(_data, event) => {
          const target = event.target as HTMLElement
          target.style.animation = 'barstrokeout 300ms forwards'
        }}
        onMouseLeave={(_data, event) => {
          const target = event.target as HTMLElement
          target.style.animation = 'barstrokein 300ms forwards'
        }}
        layers={[
          'grid',
          'axes',
          'markers',
          'bars',
          'legends',
          BarTotalsLayer,
        ]}
      />
    </Box>
  )
}
