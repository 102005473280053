/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid, CardContent, Typography, CardActions, Button, ThemeProvider,
} from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import warningIcon from 'assets/icons/warning.png'
import terms from 'common/terms'
import { customReImportButton } from 'common/CustomTheme'
import { GovsList, Pagine } from 'reducers/types'
import GovService from 'reducers/services/govService'
import { updateHasNoGovs } from 'reducers/missingGov'
import PagineService from 'reducers/services/pagineService'
import { updateHasNoPagines } from 'reducers/missingPagine'
import ReImportNotice from './ReImportNotice'

export default function MissingDates(): ReactElement {
  const dispatch = useDispatch()
  const { metricsList } = useSelector((state: RootState) => state.metrics)
  const { govs, hasNoGovs } = useSelector((state: RootState) => state.missingGov)
  const { paginesList, hasNoPagines } = useSelector((state: RootState) => state.missingPagine)
  const [noDataStatus, setNoDataStatus] = useState<GovsList[]>(undefined)
  const [pendingStatus, setPendingStatus] = useState<GovsList[]>(undefined)
  const [noDataPaginesStatus, setNoDataPaginesStatus] = useState<Pagine[]>(undefined)
  const [pendingPaginesStatus, setPendingPaginesStatus] = useState(undefined)
  const columns = metricsList?.metrics?.filter(obj => obj?.board === 'PAGINE').length !== 0 ? 3 : 4

  const getMissingGare = (comparaisonType: string) => (metricsList && metricsList.length !== 0
    ? metricsList?.gare[comparaisonType] : [])

  const getGovsList = (key: string) => (govs?.filter((gov: GovsList) => gov.comparaisonType
  === key)[0])?.govs

  const getGovsStatus = (key: string, status: string) => (govs?.filter((gov: GovsList) => gov.comparaisonType
  === key)[0])?.govs.filter(gov => gov.statut === status)

  const getPaginesStatus = (status: string) => (paginesList?.filter((pagine: Pagine) => pagine.statut === status))

  useEffect(() => {
    const noDataGovs: GovsList[] = []
    const noDataPagines: Pagine[] = []
    Object.keys(metricsList?.missing_days).map(key => (key === 'PAGINE'
      ? noDataPagines.push(getPaginesStatus('no_data'))
      : noDataGovs.push({
        govs: getGovsStatus(key, 'no_data'),
        comparaisonType: key,
      })))
    setNoDataStatus(noDataGovs)
    setNoDataPaginesStatus(noDataPagines)
    const pendingGovs: GovsList[] = []
    const pendingPagines: Pagine[] = []
    Object.keys(metricsList?.missing_days).map(key => (key === 'PAGINE'
      ? pendingPagines.push(getPaginesStatus('pending'))
      : pendingGovs.push({
        govs: getGovsStatus(key, 'pending'),
        comparaisonType: key,
      })))
    setPendingStatus(pendingGovs)
    setPendingPaginesStatus(pendingPagines[0])
  }, [govs, paginesList])

  const handleClick = (key: string) => {
    if (key !== 'PAGINE') {
      const ids: string[] = []
      metricsList.missing_days[key].forEach(day => {
        if (Object.values(day)[0] !== '') {
          ids.push(Object.values(day)[0] as string)
        }
      })
      if (ids.length !== 0) {
        dispatch(GovService.updateGovs({ ids, comparaisontype: key }))
      } else {
        dispatch(updateHasNoGovs(true))
      }
    } else {
      const ids: string[] = []
      metricsList.missing_days[key].forEach(day => {
        if (Object.values(day)[0] !== '') {
          ids.push(Object.values(day)[0] as string)
        }
      })
      if (ids.length !== 0) {
        dispatch(PagineService.updatePagines(ids))
      } else {
        dispatch(updateHasNoPagines(true))
      }
    }
  }

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      alignItems="start"
      sx={{ width: '100%', mx: '6%' }}
    >
      {metricsList && metricsList.length !== 0 ? Object.keys(metricsList?.missing_days).map((key: string) => (
        <Grid key={key} item xs={6} sm={columns}>
          <Card style={{ height: '80vh' }}>
            <CardContent sx={{ p: '25' }}>
              <Typography
                sx={{
                  fontFamily: 'Avenir', fontWeight: '900', fontSize: '24px', color: '#272727',
                }}
              >
                <img src={warningIcon} alt="" width={20} className="mr-2" />
                {key}
              </Typography>
              {getMissingGare(key).length === 0 && (
              <ReImportNotice
                comparaisonType={key}
                govs={noDataStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs}
              />
              )}
              {getMissingGare(key).length === 0 ? (
                <Typography className="missing-days">
                  {metricsList.missing_days[key].map(k => (
                    <div key={k} className="m-4">
                      {new Date(Object.keys(k)[0]).toLocaleString('fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </div>
                  ))}
                </Typography>
              ) : (
                metricsList.gare[key].length !== 0 && (
                <Typography className="notice">
                  {metricsList.gare[key]}
                </Typography>
                )
              )}
            </CardContent>
            {getMissingGare(key).length === 0 && (
            <CardActions sx={{ justifyContent: 'center' }}>
              <ThemeProvider theme={customReImportButton}>
                <Button
                  disabled={(
                    pendingStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs?.length
                     === getGovsList(key)?.length && getGovsList(key))
                    || metricsList.missing_days[key].length === 0
                    || hasNoGovs
                    || (key === 'PAGINE' && pendingPaginesStatus?.length === paginesList?.length)
                    || (key === 'PAGINE' && hasNoPagines)}
                  onClick={() => handleClick(key)}
                >
                  {(
                    key !== 'PAGINE'
                    && ((noDataStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs
                    && noDataStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs?.length !== 0)
                    || metricsList.missing_days[key].length === 0
                    || (!hasNoGovs && !getGovsList(key))))
                    || (
                      key === 'PAGINE'
                      && (!(pendingPaginesStatus?.length === paginesList?.length)
                      || (hasNoPagines && !paginesList)))
                    ? terms.MissingGov.reImport : terms.MissingGov.reImportPending}
                </Button>
              </ThemeProvider>
            </CardActions>
            )}
          </Card>
        </Grid>
      )) : <></>}
    </Grid>
  )
}
