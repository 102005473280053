import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { PORTAL_URL } from 'config/config'
import logo from 'assets/logos/logo-DGEXSOL-white.svg'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import BtSwitchColor from './BtSwitchColor'
import BtUser from './BtUser'
import BtCurrentStation from './BtCurrentStation'

type Props = {
  appName: string;
  goHomeIcon: boolean;
}

export default function TopBar({ appName, goHomeIcon }: Props): ReactElement {
  const app = useSelector((state: RootState) => state.app)
  const { metricsList } = useSelector((state: RootState) => state.metrics)

  return (
    <Grid
      container
      sx={{ justifyContent: 'space-between' }}
      className={`mastheader${app.fullscreen ? ' fullscreen' : ''}`}
    >
      <Grid item className="mastheader-logo">
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
          <header role="banner" className="mastheader-title d-none d-xl-block">
            <h1 className="text-uppercase text-white pl-3 mb-0">{appName}</h1>
          </header>
        </Stack>
      </Grid>
      {goHomeIcon && (
        <Grid item>
          <BtCurrentStation />
        </Grid>
      )}
      <Grid item>
        <Stack direction="row" spacing={1}>
          {metricsList && metricsList.length !== 0 && (
          <BtSwitchColor />
          )}
          <BtUser />
        </Stack>
      </Grid>
    </Grid>
  )
}
