import { ReactElement } from 'react'
import Container from '@mui/material/Container'
import TopBar from 'components/Common/TopBar/TopBar'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import { Button, ThemeProvider } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { customBackWardButton } from 'common/CustomTheme'
import { useDispatch } from 'react-redux'
import { updateDisplayIconNotice, updateDisplayMissingGovNotice } from 'reducers/missingGov'
import history from 'customHistory'
import terms from 'common/terms'
import PathNames from 'common/PathNames'
import MissingDates from './MissingDates'

export default function MissingGov(): ReactElement {
  const dispatch = useDispatch()

  const handleOnClick = () => {
    history.push(PathNames.Metrics)
    dispatch(updateDisplayMissingGovNotice(false))
    dispatch(updateDisplayIconNotice(true))
  }

  return (
    <>
      <TopBar appName={terms.common.appName} goHomeIcon />
      <main className="mastcontainer pl-0">
        <Fade timeout={400} in mountOnEnter unmountOnExit>
          <Container maxWidth={false} disableGutters sx={{ background: '#e8f4fc', height: '100vh' }}>
            <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
              <Grid container item xs={12} sx={{ mx: '8%' }}>
                <Grid item xs={6}>
                  <ThemeProvider theme={customBackWardButton}>
                    <Button startIcon={<ArrowBackIcon />} onClick={handleOnClick}>
                      {terms.MissingGov.backToDashboard}
                    </Button>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      mt: '40px', fontWeight: 'bold', color: '#272727', mb: '40px',
                    }}
                  >
                    {terms.MissingGov.missingGov}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: 'center', background: '#e8f4fc' }} item xs={12}>
                <MissingDates />
              </Grid>
            </Grid>
          </Container>
        </Fade>
      </main>
    </>
  )
}
