/* eslint-disable max-len */
export const terms = {
  Login: {
    pleaseconnect: 'Veuillez vous connecter',
    trying: 'Tentative de reconnexion...',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    connect: 'Se connecter',
    usernameErrorMsg: "Le nom d'utilisateur est probalement incorrect.",
    passwordErrorMsg: 'Le mot de passe est probablement incorrect.',
    englishFlag: 'drapeau anglais',
    frenchFlag: 'drapeau français',
    serverError: 'Erreur de connexion au serveur',
  },
  TopBar: {
    disconnect: 'Déconnexion',
  },
  nav: {
    home: 'Accueil',
    about: 'A propos',
    map: 'Cartographie',
    contact: 'Contact',
    chart: 'Graphiques',
    logs: 'Zones de travail et Historique',
    library: 'Bibliothèque',
    administration: 'Administration',
    traincompo: 'Matériel',
    events: 'Incidents',
  },
  common: {
    underConstruction: 'Page en construction',
    appName: 'VARIGOV',
    search: 'Rechercher...',
    sort: 'Tri',
    sortBy: 'Trier par',
    alphabeticalOrder: 'Ordre alphabétique',
    favorites: 'Favoris',
    filter: 'Filtrer',
    asc: 'Ascendant',
    dsc: 'Descendant',
    loading: 'Chargement...',
    ok: 'Ok',
    infoChangeItLater: 'Vous pouvez la modifier plus tard',
    return: 'Retour',
    delete: 'Supprimer',
    cancel: 'Annuler',
    remove: 'Effacer',
    modify: 'Modifier',
    jpegExport: 'Exporter en JPEG',
    csvExport: 'Exporter en fichier CSV',
    xlsxExport: 'Exporter en fichier Excel',
    pagineExport: 'Exporter les données PAGINE',
    continue: 'Continuer',
    access: {
      unauthorizedModalTitle: 'Accès non autorisé',
      unauthorizedModalBody: 'Merci de vous rapprocher du service compétent pour obtenir un accès personnalisé',
    },
    modalButtonClose: 'Fermer',
  },
  StationsDashboard: {
    StationsHome: {
      bannerTitle: 'Bienvenue sur Varigov',
      bannerSubtitle: 'Veuillez sélectionner une gare pour continuer',
      searchPlaceholder: 'Rechercher une gare (nom ou trigramme)',
      stationslength: 'Toutes les gares',
    },
    Dialog: {
      dialogTitle: 'Configuration requise',
      warningAdmin: 'Il semblerait que cette gare ne soit toujours pas configurée, veuillez procéder à la configuration de la gare pour continuer.',
      warningOthers: 'Il semblerait que cette gare ne soit toujours pas configurée, veuillez contacter un administrateur.',
      configStationBt: 'Configurer la Gare',

    },
    TrigramConfig: {
      returnTopBt: 'Retour à l’écran d’accueil',
      trigramValidate: 'Veuillez valider le trigramme de la gare',
      inputLabel: 'Trigramme',
      validateBt: 'Valider',
    },
    ImageConfig: {
      returnTopBt: 'Retour à la validation du trigramme',
      imageConfigTitle: 'Veuillez ajouter la photo de couverture',
      selectFile: 'Glissez-déposez une photo ou sélectionnez le fichier sur votre ordinateur',
      skipBt: 'Passer cette étape',
    },
    ImagePreview: {
      photoChange: 'Changer la photo',
      photoValidate: 'Valider la photo',
    },
    TrackConfig: {
      returnTopBt: 'Retour à la photo de couverture',
      TrackConfigSubtitle: 'Configuration des voies à quai',
      tracks: 'Voies à quai :',
      inputPlaceholder: 'Entrer le nom du nouveau quai',
      validateBt: 'Valider',
    },
    EquipmentConfig: {
      returnTopBt: 'Retour à la configuration des voies à quai',
      EquipmentConfigSubtitle: 'Configuration des codes matériels',
      equipmentCode: 'Code matériel',
      equipmentLabel: 'Libellé (facultatif)',
      inputCodePlaceholder: 'Entrer le code matériel',
      inputLabelPlaceholder: 'Entrer le libellé',
      validateBt: 'Valider la configuration de la gare',
    },
    ModalChoiceModule: {
      title: 'Choix du module',
      gov: 'Variabilité du GOV',
      re: 'Respect des normes du RE',
    },
  },
  GovOptions: {
    GovCards: {
      chooseDays: 'Je choisis mes journées à comparer',
      cardTitle1: 'Les GOV seront importés automatiquement*',
      conceptionGov: 'GOV de conception',
      conceptionGovInfo: 'Issu de la phase de conception',
      theoreticGov: 'GOV théorique',
      theoreticGovInfo: 'Livré le J-1 à 17h par le BHL',
      realGov: 'GOV réel',
      realGovInfo: 'Tel que modifié par les acteurs opérationnels',
      manuallyImport: 'J’importe manuellement mes GOV',
      cardTitle2: 'Les GOV doivent être importés manuellement',
      excelFile: "Disposer des GOV à comparer sous forme d'un fichier Excel",
      groixExtract: 'Extrait de GROIX',
      fileImport: 'Importer les fichiers',
      forComparation: 'Pour la comparaison',
      note: '*Requiert le déploiement préalable des outils OpenGOV Temps Réel et OpenGOV Conception',
    },
  },
  TabMenu: {
    govVariation: 'Variation de GOV',
    detailVariation: 'Détail variation',
    hourlyVariation: 'Taux de variation horaire',
    trackUsage: 'Utilisation des voies',
    circulations: 'Circulations',
    punctuality: 'Ponctualité',
    pagine: 'PAGINE',
    txtBtSelectedFiles: 'Fichiers sélectionnés',
    txtBtSelectedDays: 'Dates sélectionnées',
    txtBtDays: 'Dates',
    monochrome: 'Monochrome',
    multicolor: 'Multicolore',
  },
  TracksUsage: {
    divertedTrains: 'Statistiques pour les circulations dévoyées',
    noData: '( Pas de données disponibles )',
    tooltip: 'Minutes',
    tracksUsageTitle: 'Utilisation des voies',
    tracksUsageSubtitle: 'Répartition par type de circulation sur les voies à quai',
    totalOccupationTime: 'Temps total d’occupation (en minutes)',
    occupiedTracks: 'Voies occupées',
    tootlTip: 'Pour la métrique nombre total de circulations, on compte <strong>les numéros de circulations uniques</strong>. Donc si un équilibre est formé par deux circulations ayant des numéros différents, on va compter deux circulations.',
  },
  Trains: {
    filter: 'Filtrer les trains',
    trainsTitle: 'Liste des circulations',
    trainsSubtitle: 'Numéro de circulation des arrivées et départs des équilibres',
  },
  ImportGroix: {
    ImportGroixTitle: 'Importer vos fichiers Groix dans l’ordre chronologique souhaité',
    ImportGroixSubtitle: 'Fichiers au format "données graphiques" uniquement',
    previouslyImportedFiles: 'Fichiers précédemment importés',
    fileSelection: 'Glissez-déposez le fichier ou cliquez pour sélectionner le fichier depuis votre ordinateur',
    dropRejectedMsg: 'Format de fichier invalide, merci de n’utiliser que des fichiers .xls',
    dropErrorMsg: 'Une Erreur est survenue, merci d’essayer à nouveau',
    button: {
      continue: 'Continuer',
    },
  },
  Ponctuality: {
    departPieChartTitle: 'Répartition des retards en minutes au départ',
    departPieChartSubTitle: 'Écart entre les horaires théoriques et réels de départ',
    departPieChartInfoTitleH00: 'Ponctualité H00 au départ',
    departPieChartInfoTitle: 'Ponctualité 0min au départ',
    filterH00Btn: 'Appliquer les filtres H00',
    filterH00BtnTooltipText1: 'Les filtres suivants vont s’appliquer sur toutes les vues :',
    filterH00BtnTooltipText2: 'Types équilibres COM-COM et TECH-COM et gare origine',
    arrivalPieChartTitle: 'Répartition des retards en minutes à l’arrivée',
    arrivalPieChartSubTitle: 'Écart entre les horaires théoriques et réels d’arrivée',
    arrivalPieChartInfoTitle: 'Ponctualité 0min à l’arrivée',
    lineChartTitle: 'Écart entre le nombre de circulations retardées à l’arrivée et au départ',
    pieChartInfoTooltipDepart: 'Une circulation est considérée comme ponctuelle quand l’écart horaire de départ par rapport à son horaire théorique <strong>est inférieur ou égal à 0min</strong>. Les trains en avance sont donc considérés comme ponctuels.',
    pieChartInfoTooltipArrival: 'Une circulation est considérée comme ponctuelle quand l’écart horaire d’arrivée par rapport à son horaire théorique <strong>est inférieur ou égal à 0min</strong>. Les trains en avance sont donc considérés comme ponctuels.',
  },
  VariationDetail: {
    VariationDetailTitle: 'Détail variation',
    variationLimite: 'Pour maximiser les performances, le tableau affiche et exporte un maximum de 31 jours',
    menuItemGovToCompare: 'GOV à comparer',
    menuItemVariabilityCategory: 'Catégorie de variabilité',
    menuItemAllCategories: 'Toutes les catégories de variabilité',
    deletedCirculation: 'Circulation supprimée',
    notPlanifiedCirculation: 'Circulation non planifiée',
    lateOnDepartureFilterBtn: 'Retard au départ',
    lateOnArrivalFilterBtn: 'Retard à l’arrivée',
    notSpecifiedLabel: 'libellé non renseigné',
    delayFilters: {
      operationType: 'Type d‘opérations',
      noOperation: 'Aucune opération',
      greaterThan: 'Supérieur ou égal à',
      lowerThan: 'Inférieur ou égal à',
      equalTo: 'Égal à',
      between: 'Compris entre',
      placeHolder: 'Valeur en minute(s)',
    },
  },
  HourlyVariation: {
    HourlyVariationTitle: 'Taux de variation horaire',
    HourlyVariationSubtitle: 'Écart entre circulations arrivées et circulations modifiées',
    TooltipPercentage: '% de variabilité',
  },
  MissingGov: {
    missingGovNotice: 'Plusieurs GOV sont manquants aux dates sélectionnées',
    ignoreBtn: 'Ignorer',
    importGov: 'Importer mes GOV',
    missingGov: 'Gov manquants',
    backToDashboard: 'Retour au tableau de bord',
    govConception: 'Assurez-vous qu’il existe au moins <strong>une journée type</strong> dans le module <strong>Conception</strong> de l’outil <strong>OpenGov Conception</strong> qui correspond à chacune des dates ci-dessous puis cliquer sur “Ré-importer les données”. Les données seront bientôt disponibles.',
    conceptionGovAvailability: 'dans 24h.',
    missingGovs: 'Veuillez cliquer sur le bouton pour ”Ré-importer les données”. Les données seront bientôt disponibles.',
    missingGovsPending: 'En cours de vérification avec',
    openGovTR: 'OpenGOV Temps Réel',
    reImport: 'Ré-importer les données',
    reImportPending: 'Ré-import des données en cours',
  },
  Filters: {
    filters: 'Filtres',
    resetFilters: 'Réinitialiser les filtres',
    applyFilters: 'Appliquer les filtres',
    balanceType: 'Type d’équilibre',
    circulationType: 'Type de circulation',
    tracks: 'Voies à quai',
    equipment: 'Codes matériel',
    btSelectAllTracks: 'Sélectionner toutes les voies',
    btSelectAllEquipments: 'Sélectionner tous les codes matériel',
    trainNumberRange: 'Tranche de numéros',
    trainNumberStart: 'Tranche de début',
    trainNumberEnd: 'Tranche de fin',
    searchByTrainNumber: 'Recherche par numéro de circulation',
    equalTo: 'Égal à',
    startsWith: 'Commence par',
    endsWith: 'Finit par',
    contains: 'Contient',
    operator: 'Opérateur',
    value: 'Valeur',
    trainNumber: 'Numéro de train',
    originDestination: 'Origines / Destinations',
    origin: 'Origine',
    destination: 'Destination',
    timeRange: 'Plage horaire',
    startTime: 'Heure de début',
    endTime: 'Heure de fin',
    trancheNotice: 'Veuillez choisir de nouveaux filtres',
    noResultNotice: 'Il n’existe pas de données qui correspondent aux filtres choisis',
    requiresStartTime: 'Veuillez renseigner une heure de début',
    requiresEndTime: 'Veuillez renseigner une heure de fin',
  },
  DatePicker: {
    chooseDatesTitle: 'Veuillez choisir les dates pour effectuer les comparaisons',
    sectionTitle: 'Dates à comparer :',
    validateBT: 'Valider les dates',
    radioLabelDaySelection: 'Sélection par jour',
    radioLabelPeriodSelection: 'Sélection par période',
  },
  MatchColors: {
    matchColor1: 'Identique',
    matchColor2: 'Changement d\'équilibre',
    matchColor3: 'Changement d\'équilibre et Dévoiement',
    matchColor4: 'Changement de composition',
    matchColor5: 'Dévoiement',
    matchColor6: 'Changement d\'équilibre et Changement de composition',
    matchColor7: 'Dévoiement et Changement de composition',
    matchColor8: 'Suppression',
    matchColor9: 'Ajout',
    matchColor10: 'Changement d\'équilibre et Dévoiement et Changement de composition',
  },
} as const

export default terms
