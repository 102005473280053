import { ReactElement } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import terms from 'common/terms'
import { Module } from './StationCard'

interface Props {
  configGov: (value: Module) => void;
}

const ModalChoice = ({ configGov }: Props): ReactElement => (
  <div className="choice-module">
    <div className="select">
      <button
        type="button"
        onClick={() => configGov(Module.GOV)}
      >
        <span>{terms.StationsDashboard.ModalChoiceModule.gov}</span>
        <ArrowForwardIosIcon />
      </button>
      <button
        disabled
        type="button"
        onClick={() => configGov(Module.RE)}
      >
        <div>
          <span>{terms.StationsDashboard.ModalChoiceModule.re}</span>
          <span className="construct">
            {`(${terms.common.underConstruction})`}
          </span>
        </div>
        <ArrowForwardIosIcon />
      </button>
    </div>
  </div>
)

export default ModalChoice
