import { ReactElement } from 'react'

type Props = {
    slice: any;
}

export default function LineChatTooltip({ slice }: Props): ReactElement {
  return (
    <div
      style={{
        background: 'black',
        padding: '9px 12px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        opacity: '0.90',
        color: '#FFF',
        textAlign: 'center',
      }}
    >
      <div style={{
        fontSize: '1rem',
        fontWeight: '900',
      }}
      >
        {slice.points[0].data.xFormatted}
      </div>
      <div>
        <span style={{
          fontSize: '1rem',
          fontWeight: '900',
        }}
        >
          {slice.points[0].data.yFormatted}
          {' '}
          {' '}
        </span>
        <span style={{
          fontSize: '1rem',
          fontWeight: '400',
        }}
        >
          {slice.points[0].serieId.toString().substr(slice.points[0].serieId.toString().lastIndexOf('.') + 1)}

        </span>
      </div>
      <div>
        <span style={{
          fontSize: '1rem',
          fontWeight: '900',
        }}
        >
          {slice.points[1].data.yFormatted}
          {' '}
          {' '}
        </span>
        <span style={{
          fontSize: '1rem',
          fontWeight: '400',
        }}
        >
          {slice.points[1].serieId.toString().substr(slice.points[1].serieId.toString().lastIndexOf('.') + 1)}

        </span>
      </div>
    </div>
  )
}
