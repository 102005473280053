import { ReactElement } from 'react'
import imgConstruct from 'assets/images/underconstruction.svg'
import './style.scss'
import terms from 'common/terms'
import TopBar from 'components/Common/TopBar/TopBar'
import GovBanner from 'components/GovOptions/GovBanner'

const REOption = (): ReactElement => (
  <div className="re-option">
    <TopBar appName={terms.common.appName} goHomeIcon />
    <div className="header">
      <GovBanner />
    </div>
    <div className="construct">
      <img src={imgConstruct} alt="En construction" />
      <div>{terms.common.underConstruction}</div>
    </div>
  </div>

)

export default REOption
