/* eslint-disable max-len */
/* eslint-disable camelcase */
import { get } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Filters } from 'reducers/types'

export type metricsJSA = {
  gare_id: string;
  dates: string;
}

export type metricsGroix = {
  id: string;
}

export type Params = {
  uri: string;
  gov_a: string;
  gov_b: string;
  type_gov_a: string;
  type_gov_b: string;
  filters: Filters;
  category_filter: string;
}

export type ParamsGovVariation = {
  uri: string;
  gov_a: string;
  gov_b: string;
  type_gov_a: string;
  type_gov_b: string;
  title: string;
  filters: Filters;
  order: number;
}

export type ParamsDetailVariation = {
  uri: string;
  gov_a: string;
  gov_b: string;
  sorting: string;
  type_gov_a: string;
  type_gov_b: string;
  category_filter: string;
  filters: Filters;
  filterArrival: any;
  filterDeparture: any;
}

export type ParamsPontuality = {
  uri: string;
  gov_a: string;
  gov_b: string;
  type_gov_a: string;
  type_gov_b: string;
  circulation_status?: string;
  filters: Filters;
}

export type ParamsTrackUsage = {
  uri: string;
  gov_a: string;
  gov_b: string;
  aggregation_category: string;
  type_gov_a: string;
  type_gov_b: string;
  filters: Filters;
}

export type ParamsTrainsList = {
  uri: string;
  gov_a: string;
  type_gov_a: string;
  sorting: string;
  filters: Filters;
  order?: number;
}

const getMetricsJsa = createAsyncThunk<any, metricsJSA, ThunkApi>(
  'metrics/getMetricsJsa',
  async (params: metricsJSA, thunkApi) => {
    try {
      const metricsList = await get('/gov/metriques/jsa/', params)
      return metricsList
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getMetricsGroix = createAsyncThunk<any, metricsGroix, ThunkApi>(
  'metrics/getMetricsGroix',
  async (params: metricsGroix, thunkApi) => {
    try {
      const metricsList = await get('/gov/metriques/groix/', params)
      return metricsList
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getDepartPonctuality = createAsyncThunk<any, ParamsPontuality, ThunkApi>(
  'metrics/getDepartPonctuality',
  async (params: ParamsPontuality, thunkApi) => {
    try {
      const ponctuality = await get(`${params.uri}`, {
        gov_a: params.gov_a,
        gov_b: params.gov_b,
        type_gov_a: params.type_gov_a,
        type_gov_b: params.type_gov_b,
        circulation_status: params.circulation_status,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      return ponctuality
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getArrivalPonctuality = createAsyncThunk<any, ParamsPontuality, ThunkApi>(
  'metrics/getArrivalPonctuality',
  async (params: ParamsPontuality, thunkApi) => {
    try {
      const ponctuality = await get(`${params.uri}`, {
        gov_a: params.gov_a,
        gov_b: params.gov_b,
        type_gov_a: params.type_gov_a,
        type_gov_b: params.type_gov_b,
        circulation_status: params.circulation_status,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      return ponctuality
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getPonctualityLineChart = createAsyncThunk<any, ParamsPontuality, ThunkApi>(
  'metrics/getPonctualityLineChart',
  async (params: ParamsPontuality, thunkApi) => {
    try {
      const ponctuality = await get(`${params.uri}`, {
        gov_a: params.gov_a,
        gov_b: params.gov_b,
        type_gov_a: params.type_gov_a,
        type_gov_b: params.type_gov_b,
        circulation_status: params.circulation_status,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      return ponctuality
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getTrainsList = createAsyncThunk<any, ParamsTrainsList, ThunkApi>(
  'metrics/getTrainsList',
  async (params: ParamsTrainsList, thunkApi) => {
    try {
      const trainsList = await get(`${params.uri}`, {
        gov_a: params.gov_a,
        type_gov_a: params.type_gov_a,
        sorting: params.sorting,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      trainsList.order = params.order
      return trainsList
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getTrackUsage = createAsyncThunk<any, ParamsTrackUsage, ThunkApi>(
  'metrics/getTrackUsage',
  async (params: ParamsTrackUsage, thunkApi) => {
    try {
      const trackUsage = await get(`${params.uri}`, {
        aggregation_category: params.aggregation_category,
        gov_a: params.gov_a,
        gov_b: params.gov_b,
        type_gov_a: params.type_gov_a,
        type_gov_b: params.type_gov_b,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      return trackUsage
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getHourlyVariation = createAsyncThunk<any, Params, ThunkApi>(
  'metrics/getHourlyVariation',
  async (params: Params, thunkApi) => {
    try {
      const hourlyVariation = await get(`${params.uri}`, {
        gov_a: params.gov_a !== '' ? params.gov_a : null,
        gov_b: params.gov_b !== '' ? params.gov_b : null,
        type_gov_a: params.type_gov_a,
        type_gov_b: params.type_gov_b,
        category_filter: params.category_filter !== 'all' ? params.category_filter : null,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      return hourlyVariation.reverse()
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const filterArrivalGTE = params => {
  switch (params.filterArrival.operation) {
    case 'arrival_delay_gte':
      return params.filterArrival.value1
      break
    case 'between_delay':
      return params.filterArrival.value1
      break
    default:
      return null
  }
}

const filterArrivalLTE = params => {
  switch (params.filterArrival.operation) {
    case 'arrival_delay_lte':
      return params.filterArrival.value1
      break
    case 'between_delay':
      return params.filterArrival.value2
      break
    default:
      return null
  }
}

const filterDepartureGTE = params => {
  switch (params.filterDeparture.operation) {
    case 'departure_delay_gte':
      return params.filterDeparture.value1
      break
    case 'between_delay':
      return params.filterDeparture.value1
      break
    default:
      return null
  }
}

const filterDepartureLTE = params => {
  switch (params.filterDeparture.operation) {
    case 'departure_delay_lte':
      return params.filterDeparture.value1
      break
    case 'between_delay':
      return params.filterDeparture.value2
      break
    default:
      return null
  }
}

const getDetailVariation = createAsyncThunk<any, ParamsDetailVariation, ThunkApi>(
  'metrics/getDetailVariation',
  async (params: ParamsDetailVariation, thunkApi) => {
    try {
      const detailVariation = await get(`${params.uri}`, {
        gov_a: params.gov_a !== '' ? params.gov_a : null,
        gov_b: params.gov_b !== '' ? params.gov_b : null,
        type_gov_a: params.type_gov_a,
        type_gov_b: params.type_gov_b,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        sorting: params.sorting,
        category_filter: params.category_filter !== 'all' ? params.category_filter : null,
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        arrival_delay_gte: filterArrivalGTE(params),
        arrival_delay_lte: filterArrivalLTE(params),
        arrival_delay_equal: params.filterArrival.operation === 'arrival_delay_equal' ? params.filterArrival.value1 : null,
        departure_delay_gte: filterDepartureGTE(params),
        departure_delay_lte: filterDepartureLTE(params),
        departure_delay_equal: params.filterDeparture.operation === 'departure_delay_equal' ? params.filterDeparture.value1 : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      return detailVariation
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getGovVariation = createAsyncThunk<any, ParamsGovVariation, ThunkApi>(
  'metrics/getGovVariation',
  async (params: ParamsGovVariation, thunkApi) => {
    try {
      const govVariation = await get(`${params.uri}`, {
        gov_a: params.gov_a !== '' ? params.gov_a : null,
        gov_b: params.gov_b !== '' ? params.gov_b : null,
        type_gov_a: params.type_gov_a,
        type_gov_b: params.type_gov_b,
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : null,
        category_filter: 'all',
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
      })
      govVariation.title = params.title
      govVariation.order = params.order
      return govVariation
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getCategoriesVariabiliteDetail = createAsyncThunk<Array<any>>(
  'metrics/getCategoriesVariabiliteDetail',
  async () => {
    const response = await get('/usage_reseau/variability_categories/',
      { metric_slug: 'gov_variability_detail' })
    return response.detail
  },
)

const getCategoriesVariabiliteHourly = createAsyncThunk<Array<any>>(
  'metrics/getCategoriesVariabiliteHourly',
  async () => {
    const response = await get('/usage_reseau/variability_categories/',
      { metric_slug: 'hourly_rate_gov_variability' })
    return response.detail
  },
)

const getVariabilityDefinition = createAsyncThunk<Array<any>>(
  'metrics/getVariabilityDefinition',
  async () => {
    const response = await get('/usage_reseau/variability_definition/')
    return response
  },
)

const getMetricsParams = createAsyncThunk<Array<any>>(
  'metrics/getMetricsParams',
  async () => {
    const response = await get('/usage_reseau/metrics/')
    return response.filter(metric => metric.slug === 'gov_variability_detail')
  },
)

const metricsService = {
  getMetricsJsa,
  getMetricsGroix,
  getDepartPonctuality,
  getArrivalPonctuality,
  getPonctualityLineChart,
  getTrainsList,
  getTrackUsage,
  getHourlyVariation,
  getDetailVariation,
  getGovVariation,
  getCategoriesVariabiliteDetail,
  getCategoriesVariabiliteHourly,
  getVariabilityDefinition,
  getMetricsParams,
}

export default metricsService
