/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setRequiredConfig, updateActiveStation } from 'reducers/stations'
import history from 'customHistory'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'
import CardActionArea from '@mui/material/CardActionArea'
import StationsService from 'reducers/services/stationsService'
import { Station } from 'reducers/types'
import PathNames from 'common/PathNames'
import ModalWrapper from 'components/Common/ModalWrapper'
import terms from 'common/terms'
import ModalChoice from './ModalChoice'

export enum Module {
  GOV = 'GOV',
  RE = 'RE',
}

type Props = {
    station: Station;
}

export default function StationCard({ station }: Props): ReactElement {
  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState('')
  const [openModal, setOpenModal] = useState<boolean>(false)

  const getAuthConfig = () => ({
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleFavorite = (stationId: any, isFavorite: boolean) => {
    const object = {
      stationID: stationId,
      params: {
        est_favorite: !isFavorite,
      },
    }
    dispatch(StationsService.patchStation(object))
  }

  const configTrigram = () => {
    dispatch(updateActiveStation(station))
    setTimeout(() => {
      history.push(PathNames.TrigramConfig)
    }, 500)
  }

  const configGov = (selectedModule: Module) => {
    setOpenModal(false)
    switch (selectedModule) {
      case Module.GOV:
        if (station.est_config) {
          history.push(PathNames.OptionChoice)
        } else {
          dispatch(setRequiredConfig(true))
        }
        break
      case Module.RE:
        history.push(PathNames.REOption)
        break
      default:
        break
    }
  }

  const handleOpenModal = () => {
    dispatch(updateActiveStation(station))
    setOpenModal(true)
  }

  useEffect(() => {
    if (station.image !== null) {
      const baseURL = station.image
      const config = getAuthConfig()
      axios.get(baseURL, config)
        .then(response => {
          setImageUrl(response.data)
        })
        .catch(error => {
          if (error.response) {
            setImageUrl(error.response.data)
          } else if (error.request) {
            // eslint-disable-next-line no-console
            console.log('Error', error.message)
          } else {
            // eslint-disable-next-line no-console
            console.log('Error', error.message)
          }
        })
    }
  }, [])

  return (
    <Card
      sx={{ width: '90%', height: 220 }}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        backgroundSize: 'cover',
        margin: 10,
        alignSelf: 'flex-start',
        boxShadow: '0px -200px 75px -129px rgba(0,0,0,0.75) inset',
      }}
    >
      <CardHeader
        sx={{ minHeight: 72 }}
        avatar={(station.est_admin && (
        <IconButton
          aria-label="settings"
          onClick={() => configTrigram()}
        >
          <SettingsIcon sx={{ color: 'white' }} />
        </IconButton>
        )
                      )}
        action={(
          <IconButton
            aria-label="favorite"
            onClick={() => toggleFavorite(station.id, station.est_favorite)}
          >
            {station.est_favorite
              ? <FavoriteIcon sx={{ color: 'white' }} />
              : <FavoriteBorderIcon sx={{ color: 'white' }} />}
          </IconButton>
                    )}
      />
      <CardActionArea
        aria-label="settings"
        onClick={handleOpenModal}
        className="card-action"
      >
        <CardContent sx={{ paddingTop: '0', justifyContent: 'center' }}>
          <Typography variant="h3" sx={{ color: 'white', textAlign: 'center', fontWeight: 'bolder' }}>
            {station.abv_traction}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
            {station.nom_gare}
          </Typography>
        </CardActions>
      </CardActionArea>
      <ModalWrapper
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={terms.StationsDashboard.ModalChoiceModule.title}
        variant="primary"
      >
        <ModalChoice
          configGov={configGov}
        />
      </ModalWrapper>
    </Card>
  )
}
