import { useEffect, Suspense, ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'
import { RootState } from 'Store'
import Loader from 'components/Common/Loader/Loader'
import Home from 'components/Home'
import TrigramConfiguration from 'components/Configuration/TrigramConfig'
import ImageConfiguration from 'components/Configuration/ImageConfig'
import TrackConfiguration from 'components/Configuration/TrackConfig'
import EquipmentConfig from 'components/Configuration/EquipmentConfig'
import GovOptions from 'components/GovOptions/GovOptions'
import GovComparation from 'components/GovOptions/GovComparation'
import { auth } from '@osrdata/app_core'
import history from 'customHistory'
import 'App.css'
import GroixImport from 'components/GroixImport/GroixImport'
import Station from 'components/Station/Station'
import ImagePreview from 'components/Configuration/ImagePreview'
import MissingGov from 'components/MissingGov/MissingGov'
import PathNames from 'common/PathNames'
import REOption from 'components/REOption'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
    if (window.performance) {
      if (performance.navigation.type === 1) {
        history.push(PathNames.Home)
      }
    }
  }, [])

  return (
    <>
      <Suspense fallback={<Loader center />}>
        {isLogged && (
        <Router history={history}>
          <Switch>
            <Route exact component={Home} path={PathNames.Home} />
            <Route exact component={Station} path={PathNames.Metrics} />
            <Route exact component={GovOptions} path={PathNames.OptionChoice} />
            <Route exact component={GovComparation} path={PathNames.DateChoice} />
            <Route exact component={GroixImport} path={PathNames.FileImport} />
            <Route exact component={TrigramConfiguration} path={PathNames.TrigramConfig} />
            <Route exact component={EquipmentConfig} path={PathNames.EquipmentConfig} />
            <Route exact component={ImageConfiguration} path={PathNames.ImageConfig} />
            <Route exact component={ImagePreview} path={PathNames.ImagePreviz} />
            <Route exact component={TrackConfiguration} path={PathNames.TrackConfig} />
            <Route exact component={MissingGov} path={PathNames.MissingGov} />
            <Route exact component={REOption} path={PathNames.REOption} />
            <Redirect to={PathNames.Home} />
          </Switch>
        </Router>
        )}
      </Suspense>
    </>
  )
}
